body .uwy.userway_p1 .uai {
	bottom: 80px!important;
	left: auto!important;
	right: -40px!important;
	top: auto!important;
}

body .uwy .uai {
	width: 45px!important;
	height: 45px!important;
	min-width: 45px!important;
	min-height: 45px!important;
	max-width: 45px!important;
	max-height: 45px!important;
}

.uwy .uai img:not(.check_on), body .uwy .uai img:not(.check_on) {
	height: 45px!important;
	width: 45px!important;
}

body .ae-right {
	bottom: 115px!important;
	left: auto!important;
	right: -5px!important;
	top: auto!important;
}

#ae_launcher [class*=' ae-icon-launcher'], #ae_launcher [class*=' ae-icon-launcher'] [class*=' path'], #ae_launcher [class^=ae-icon-launcher]{

	font-size: 50px !important;

}

body .equally-ai___fab {
	bottom: 190px!important;
	left: auto!important;
	right: 10px!important;
	top: auto!important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.answer-1 {
	width: 7.0em;
	height: 2.500em;
	border: 0.0625em solid blue;
	box-sizing: border-box;
	font-size: 0.875em;
	line-height: 1.125em;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	text-align: center;
	align-items: center;
	/* text-indent: 0.5em; */
	padding-left: -10px;
	padding-right: -10px;
}
.answer-2 {
	color: black;
	width: 6.5em;
	height: 2.500em;
	border: 0.0625em solid blue;
	box-sizing: border-box;
	font-size: 0.875em;
	line-height: 1.125em;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	text-align: center;
	align-items: center;
	/* text-indent: 0.5em; */
	padding-left: -10px;
	padding-right: -10px;
  background-color: #FFA63D
}
.answer-3 {
	color: white;
	width: 6.5em;
	height: 2.500em;
	border: 0.0625em solid blue;
	box-sizing: border-box;
	font-size: 0.875em;
	line-height: 1.125em;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	text-align: center;
	align-items: center;
	/* text-indent: 0.5em; */
	padding-left: -10px;
	padding-right: -10px;
  background-color: #ce0000;
}
.answer-4 {
	color: white;
	width: 6.5em;
	height: 2.500em;
	border: 0.0625em solid blue;
	box-sizing: border-box;
	font-size: 0.875em;
	line-height: 1.125em;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	text-align: center;
	align-items: center;
	/* text-indent: 0.5em; */
	padding-left: -10px;
	padding-right: -10px;
  background-color: #117e5a;
}
.answer-5 {
	width: 11em;
	height: 2.500em;
	border: 0.0625em solid blue;
	box-sizing: border-box;
	font-size: 0.875em;
	line-height: 1.125em;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	text-align: center;
	align-items: center;
	/* text-indent: 0.5em; */
	padding-left: -10px;
	padding-right: -10px;
  background-color: rgba(59, 130, 246, 0.5);
}
.answer-6 {
	width: 6.5em;
	height: 2.500em;
	border: 0.0625em solid blue;
	box-sizing: border-box;
	font-size: 0.875em;
	line-height: 1.125em;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	text-align: center;
	align-items: center;
	/* text-indent: 0.5em; */
	padding-left: -10px;
	padding-right: -10px;
}

.score-1 {
	width: 1.5em;
	height: 1.500em;
	box-sizing: border-box;
	font-size: 0.875em;
	line-height: 1.125em;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	text-align: center;
	align-items: center;
	/* text-indent: 0.5em; */
	padding-left: -10px;
	padding-right: -10px;
  background-color: #27C478;
}
.score-2 {
	width: 1.5em;
	height: 1.500em;
	box-sizing: border-box;
	font-size: 0.875em;
	line-height: 1.125em;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	text-align: center;
	align-items: center;
	/* text-indent: 0.5em; */
	padding-left: -10px;
	padding-right: -10px;
  background-color: #FFA63D
}
.score-3 {
	width: 1.5em;
	height: 1.500em;
	box-sizing: border-box;
	font-size: 0.875em;
	line-height: 1.125em;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	text-align: center;
	align-items: center;
	/* text-indent: 0.5em; */
	padding-left: -10px;
	padding-right: -10px;
  background-color: #D82903;
}
.answer-8 {
	width: 7.0em;
	height: 3.800em;
	border: 0.0625em solid blue;
	box-sizing: border-box;
	font-size: 0.875em;
	line-height: 1.125em;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	text-align: center;
	align-items: center;
	/* text-indent: 0.5em; */
	padding-left: -10px;
	padding-right: -10px;
}

.wavy {
	text-decoration-line: underline;
}
  

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
